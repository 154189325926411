<template>
  <div class="learn-nio">
    <img class="learn-nio-bg" src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/蔚来已来-顶图.png?imageView2/interlace/1" alt="" />
    <div class="learn-wish">
      Blue Sky Coming<br />共创可持续和更美好的未来
    </div>
    <div class="nio-logo">
      <img src="../../assets/img/nio-logo.png" alt="" />
    </div>
    <div class="learn-desc">
      我们的标识设计也传达着这份信念：
      <br />上方是天空，象征开放、远见和美好的未来；
      <br />下方是延伸向地平线的道路，象征方向、行动和前进的动力。
    </div>

    <div style="background: #f7f8fa; padding-bottom: 110px">
      <div class="school-activity">
        <div class="title">企业介绍</div>
        <div class="activity-subTitle">
          成为技术与体验领先的用户企业
        </div>
        <ul class="activity-list-wrap">
          <li
            class="activity-list activity-list-left"
            @click="active = 0"
            @mouseenter="active = 0"
          >
            <img
              src="../../assets/img/learn-1.png"
              alt=""
              class="activity-list-bg"
            />
            <p class="activity-list-title">共同成长的用户社区</p>
            <p class="activity-list-sub-title">
              我们致力于通过智能电动汽车，为用户提供高品质服务与创新性补能方案，打造一个共同成长的用户社区。
            </p>
          </li>
          <li
            class="activity-list activity-list-left"
            @click="active = 1"
            @mouseenter="active = 1"
          >
            <img
              src="../../assets/img/learn-2.png"
              alt=""
              class="activity-list-bg"
            />
            <p class="activity-list-title">科技的力量</p>
            <p class="activity-list-sub-title">
              智能、电动和智能驾驶是汽车的未来。我们致力于持续创新行业领先的技术，引领变革，为生活创造价值。
            </p>
          </li>
          <li
            class="activity-list activity-list-left"
            @click="active = 2"
            @mouseenter="active = 2"
          >
            <img
              src="../../assets/img/learn-3.png"
              alt=""
              class="activity-list-bg"
            />
            <p class="activity-list-title">设计驱动</p>
            <p class="activity-list-sub-title">
              我们秉承蔚来设计基因，致力于打造纯粹、易用、有向往感的产品，为用户带来全感官的愉悦体验。
            </p>
          </li>
          <li
            class="activity-list"
            @click="active = 3"
            @mouseenter="active = 3"
          >
            <img
              src="../../assets/img/learn-4.png"
              alt=""
              class="activity-list-bg"
            />
            <p class="activity-list-title">NIO Power</p>
            <p class="activity-list-sub-title">
              依托蔚来能源云技术及广泛布局的充换电设施网络、搭建“可充可换可升级”的能源服务体系，为车主提供全场景化的加电服务。
            </p>
          </li>
        </ul>
        <div class="card">
          <div
            class="sanjiao"
            :style="style"
          />
          <img class="card-img" :src="cardList[active].portrait" alt="" />
          <div class="card-name-desc">
            <div class="card-name">
              <span>{{ cardList[active].name }}</span
              >{{ cardList[active].grade }}
            </div>
            <div class="card-desc">{{ cardList[active].desc }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="school-activity">
      <ul class="list-wrap">
        <li class="left">
          <div class="activity-bg" style="margin-right: 50px">
            <img src="../../assets/img/learn-5.png" alt="" />
          </div>
          <div class="activity-desc">
            <p class="desc1">我们是一家由价值观驱动的企业</p>
            <p class="desc2">Blue Sky Coming</p>
            <p style="margin-bottom: 40px">
              共创可持续和更美好的未来
            </p>
            <p class="desc2">成为技术与体验领先的用户企业</p>
            <p style="margin-bottom: 40px">
              为用户满意而存在。通过技术创新，开发极致的产品，提供超越期待的服务，打造共同成长的社区。
            </p>
            <p class="desc2">真诚、关爱、远见、行动</p>
            <p style="margin-bottom: 40px">
              真诚、关爱、远见、行动是我们的行为准则。
            </p>
            <p class="desc2">独有的价值驱动机制</p>
            <p>
              通过价值创造、价值评估、价值成长和价值激励的闭环，帮助每位伙伴更好的融入蔚来并收获长期发展，与蔚来共同成长。
            </p>
          </div>
        </li>
        <li class="left">
          <div class="activity-desc" style="margin-right: 50px">
            <p class="desc1">在蔚来持续成长</p>
            <p class="desc2">新员工着陆计划，更快融入蔚来</p>
            <p style="margin-bottom: 40px">
              每位新加入蔚来的伙伴，都将加入到定制化的新员工入职培训项目，辅助在适应期顺利着陆。针对应届毕业生伙伴，蔚来提供0-3年的发展规划，帮助萌新快速熟悉业务，融入团队，完成学生到蔚来人的转变。
            </p>
            <p class="desc2">丰富的培训体系</p>
            <p style="margin-bottom: 40px">
              蔚来践⾏学习型组织模式，拥有非常专业且多元的学习资源，蔚来有内部学习平台和专业学院，针对不同业务群体，从价值⼒、专业⼒、领导⼒、变⾰⼒四个维度全⽅⾯点燃和赋能员工的提升发展。
            </p>
            <p class="desc2">多元的职业发展路径</p>
            <p style="margin-bottom: 40px">
              基于公司价值链，蔚来建⽴了独特的员工职业发展体系- NCP（NIO Career
              Path），对所有岗位都平等地提供了基于能力水平的职级成⻓台阶，统一全球人才发展通道、标准和阶梯，鼓励员工根据自身特点，有效规划职业生涯，不断精进专业能力。
            </p>
          </div>
          <div class="activity-bg">
            <img src="../../assets/img/learn-6.png" alt="" />
          </div>
        </li>
        <li class="left">
          <div class="activity-bg" style="margin-right: 50px">
            <img src="../../assets/img/learn-7.png" alt="" />
          </div>
          <div class="activity-desc" style="padding-top: 10px">
            <p class="desc1" style="margin-bottom: 12px">多方位的福利与关怀</p>
            <p style="margin-bottom: 40px">
              蔚来为员工提供多方位的福利，包括但不限于员工的购车方案、商业保险等，旨在创造一个温暖、安心、有保障的工作环境。
            </p>
            <p class="desc2">全员持股</p>
            <p style="margin-bottom: 40px">
              蔚来是一家全员持股的公司，每一位员工都是蔚来的股东，大家一起分享公司的成长喜悦。
            </p>
            <p class="desc2">购车福利</p>
            <p style="margin-bottom: 40px">
              在职期间购买全系车型均可享受员工购车优惠，在职期间可以享受不限次数免费换电。
            </p>
            <p class="desc2">便利与关爱</p>
            <p style="margin-bottom: 40px">
              蔚来为员工提供衣食住行全方位服务，包括通讯津贴、通勤班车、员工专属到店福利等。
            </p>
            <p class="desc2">健康关怀</p>
            <p>
              蔚来关注每一位员工的身心健康，除年度体检、心理健康咨询外，还会定期举行健康咨询活动。
            </p>
          </div>
        </li>
        <li class="left">
          <div class="activity-desc" style="margin-right: 50px">
            <p class="desc1">多彩的生活</p>
            <p class="desc2">丰富的兴趣俱乐部</p>
            <p style="margin-bottom: 40px">
              篮球、瑜伽、电竞、剧本杀、飞盘、骑行等多种多样的兴趣社团，在工作之余结识兴趣相投的伙伴，还有“蔚来杯”篮球、足球、电竞等系列赛事，待你解锁！与你热爱的一切不期而遇。
            </p>
            <p class="desc2">富有创意的员工活动</p>
            <p style="margin-bottom: 40px">
              圣诞节的姜饼、中秋节的月饼、母亲节跨越时空合影、父亲节的亲子户外营、地球日的徒步旅行，与每一位NIOer分享欢乐，共同成长。
            </p>
            <p class="desc2">遍布全球的办公场地</p>
            <p style="margin-bottom: 40px">
              蔚来在上海、合肥、北京、南京、深圳、杭州、圣何塞、慕尼黑、牛津、柏林、布达佩斯、新加坡等地设立了研发与生产机构，并在中国、挪威、德国、荷兰、瑞典、丹麦等国家建立销售和服务体系，覆盖全球超300个城市的用户。
            </p>
          </div>
          <div class="activity-bg">
            <img src="../../assets/img/learn-8.png" alt="" />
          </div>
        </li>
      </ul>
    </div>

    <div class="learn-ESG">
      <div class="learn-ESG-title">蔚来，源自一个清朗天空的愿景。</div>
      <div class="learn-ESG-sub-title">
        更好的智能电动汽车产品、更好的汽车拥有体验，会让越来越多的人喜欢上智能电动汽车，
        我们所期盼的蔚蓝天空就会更快地到来，我们所共同居住的蔚蓝星球将会更可持续地发展。
      </div>
      <div class="more" style="color: #00bebe" @click="jump">
        了解更多
        <img
          src="../../assets/img/detail-icon.png"
          alt=""
          style="width: 26.5px; margin-top: -5px"
        />
      </div>
    </div>

    <div class="school-game">
      <div class="title">“蔚来杯”大学生方程式系列赛事</div>
      <div class="sub-title">
        中国大学生方程式系列赛事创立于 2009
        年，由中国汽车工程学会主办。自2014年蔚来成立起，我们已连续九年支持中国大学生方程式系列赛事，
        为大学生提供一个展示、接触、学习前沿新能源技术的平台，为中国汽车工业全能型人才培养贡献力量。
      </div>
      <div
        class="more"
        style="color: #00bebe"
        @click="$router.push('/equation')"
      >
        了解更多
        <img
          src="../../assets/img/detail-icon.png"
          alt=""
          style="width: 26.5px; margin-top: -5px"
        />
      </div>
      <div class="video-play">
        <video
          controls
          ref="video"
          class="abs"
          src="https://cdn-fx.nio.com/fx/nio-school-front/__cdn__/蔚来校园视频.mp4"
          preload
        ></video>
      </div>
    </div>

    <Swiper></Swiper>
  </div>
</template>

<script>
import Swiper from "../home/Swiper";

export default {
  name: "learnNio",
  data() {
    return {
      cardList: [
        {
          name: "李斌",
          grade: "蔚来创始人、董事长、CEO",
          portrait: require("@/assets/img/touxiang-1.png"),
          desc: "为用户满意而存在。通过技术创新，开发极致的产品，提供超越期待的服务，打造共同成长的社区。",
        },
        {
          name: "任少卿",
          // grade: '蔚来智能驾驶研发助理副总裁',
          grade: "蔚来智能驾驶研发副总裁",
          portrait: require("@/assets/img/touxiang-2.png"),
          desc: "我们最终希望自动驾驶是解决两个问题，第一个是减少事故，第二个是解放时间。随着自动驾驶技术的发展与完善，汽车将改变人类的生活形态。",
        },
        {
          name: "Kris Tomasson",
          // grade: '蔚来设计副总裁',
          grade: "蔚来设计高级副总裁",
          portrait: require("@/assets/img/touxiang-3.png"),
          desc: "在蔚来我们设计的不仅仅是汽车，更是在创造体验，创造你所见、所闻、所感知的一切。",
        },
        {
          name: "沈斐",
          grade: "蔚来能源高级副总裁",
          portrait: require("@/assets/img/touxiang-4.png"),
          desc: "换电将为能源领域带来更多的想象空间，如果你的底层原则是对的，你越做越能发现这个事情的更多价值。",
        },
      ],
      active: 0,
    };
  },
  computed: {
    style() {
      if (this.active === 0) {
        return { position: "absolute", top: "-1.2rem", left: "9rem" };
      }
      if (this.active === 1) {
        return { position: "absolute", top: "-1.2rem", left: "33rem" };
      }
      if (this.active === 2) {
        return { position: "absolute", top: "-1.2rem", left: "56rem" };
      }
      if (this.active === 3) {
        return { position: "absolute", top: "-1.2rem", left: "79rem" };
      }
    },
  },
  methods: {
    jump() {
      window.open("https://www.nio.cn/esg");
    },
  },
  components: {
    Swiper,
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>
.more {
  font-size: 16px;
  color: #040b29;
  line-height: 36.66px;
  margin-bottom: 28px;
  cursor: pointer;
}

.learn-ESG {
  background: url("../../assets/img/learn-9.png") no-repeat center;
  background-size: cover;
  width: 100%;
  height: 700px;
  position: relative;
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  flex-direction: column;
  padding-top: 128px;
  color: #ffffff;
}

.learn-ESG-title {
  font-size: 48px;
  /*color: #040B29;*/
  line-height: 76.13px;
  margin-bottom: 14px;
}

.learn-ESG-sub-title {
  font-size: 16px;
  line-height: 36.66px;
  /*color: #040B29;*/
  margin-bottom: 30px;
  width: 70%;
}

.left {
  display: flex;
  margin-bottom: 100px;
}

.activity-bg {
  min-width: 520px;
  width: 520px;
  height: 560px;
}

.activity-bg img {
  width: 100%;
  height: 100%;
}

.activity-desc {
  text-align: left;
  padding-top: 50px;
  font-size: 14px;
  line-height: 22.2px;
  color: #040b29;
}

.desc1 {
  font-size: 28px;
  line-height: 44.41px;
  margin-bottom: 40px;
}

.desc2 {
  font-size: 18px;
  line-height: 28.55px;
  margin-bottom: 8px;
}

.card {
  width: 100%;
  height: 170px;
  box-shadow: 0 0 15px 0 rgba(24, 26, 32, 0.08);
  display: flex;
  align-items: center;
  margin-top: 40px;
  padding-left: 32px;
  padding-right: 62px;
  position: relative;
  background: #ffffff;
}

.sanjiao{
  width: 0;
  height: 0;
  &::before{
    content: '';
    display: block;
    width: 100%;
    border: 14px solid transparent;
    border-bottom: 17px solid #fff;
    border-top: none;
  }
}

.card-img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}

.card-name-desc {
  text-align: left;
  margin-left: 24px;
}

.card-name {
  font-size: 16px;
  line-height: 25.38px;
  color: rgba(0, 15, 22, 0.5);
  align-items: center;
  margin-bottom: 10px;
}

.card-name span {
  font-size: 26px;
  color: rgba(0, 15, 22, 1);
  margin-right: 13px;
  line-height: 41.24px;
}

.card-desc {
  font-size: 16px;
  color: rgba(0, 15, 22, 1);
  line-height: 25.38px;
  height: 50px;
}

.learn-nio {
  text-align: center;
}

.learn-nio-bg {
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 500px;
}

.learn-wish {
  font-size: 24px;
  line-height: 48.38px;
  color: #000f16;
  margin-top: 73px;
}

.nio-logo {
  display: flex;
  /*padding: 0 80px;*/
  /*width: 100%;*/
  /*margin-top: 73px;*/
  width: 1200px;
  margin: 73px auto;
}

.nio-logo img {
  flex: 1;
  height: 575.89px;
  width: 100%;
}

.learn-desc {
  font-size: 14px;
  line-height: 35.15px;
  color: #000f16;
  margin-top: 73px;
  margin-bottom: 52px;
}

.school-activity {
  width: 1200px;
  margin: 0 auto;
  padding-top: 90px;
  /*text-align: center;*/
}

.activity-subTitle {
  font-size: 14px;
  line-height: 22.2px;
  margin-bottom: 60px;
  text-align: center;
}

.activity-list-wrap {
  display: flex;
}

.activity-list {
  position: relative;
  flex: 1;
  box-shadow: 0 0 15px 0 rgba(24, 26, 32, 0.08);
  padding-bottom: 20px;
  cursor: pointer;
  background: #ffffff;
  transition: all 0.2s ease;
  overflow: hidden;
}

.activity-list:hover .activity-list-bg {
  transform: scale(1.1);
}

.activity-list-left {
  margin-right: 32px;
}

.activity-list .activity-list-bg {
  width: 100%;
  height: 222px;
  transition: all 0.2s ease;
}

.activity-list-3 img {
  height: 253px;
}

.activity-list-title {
  font-size: 18px;
  color: #000000;
  line-height: 28.55px;
  margin-left: 24px;
  margin-top: 20px;
  text-align: left;
}

.activity-list-sub-title {
  font-size: 12px;
  color: #000000;
  line-height: 19.03px;
  margin-left: 24px;
  margin-top: 12px;
  margin-right: 24px;
  text-align: left;
}

.title {
  font-size: 36px;
  color: #000000;
  line-height: 57.1px;
  margin-bottom: 8px;
  text-align: center;
}

.school-game {
  width: 1000px;
  margin: 0 auto;
  text-align: center;
  padding-top: 90px;
  padding-bottom: 90px;
}

.title {
  font-size: 36px;
  color: #000000;
  line-height: 57.1px;
  margin-bottom: 8px;
  text-align: center;
}

.sub-title {
  font-size: 14px;
  line-height: 22.2px;
  margin-bottom: 14px;
  width: 80%;
  margin-left: 10%;
}

.video-play {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000000;
  width: 1000px;
  height: 482px;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  left: 0;
  top: 0;
}

.more {
  font-size: 16px;
  color: #040b29;
  line-height: 36.66px;
  margin-bottom: 28px;
  cursor: pointer;
}
</style>
